<template>

  <div v-if="data.role_name && data_subscription" class="">

    <div class="premium_active">

      <div class="logo">
        <img src="/public/logo/business_black.svg">
      </div>
      <div class="info">

        <h2>{{ $t('brand_manager_profile_settings_title') }}</h2>

          <div v-if="data_subscription.end_date" class="scancel">
            {{ $t('brand_manager_profile_settings_valid') }}
            {{ data_subscription.end_date }}.
          </div>

      </div>

    </div>

  </div>

</template>

<script setup>

const {$api} = useNuxtApp()

const props = defineProps({
  data: {
    type: Object    
  }
});

const loadSubscription = () => {
  $api.getB2BSubscription().then(res => {
    if(res){
      data_subscription.value = res;
      return data_subscription
    }
  })
}

const { pending: is_loading,  data: data_subscription } = await useLazyAsyncData('data_subscription', async () => await loadSubscription())
 
/* 
export default { 
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {   
      data_subscription: {}
    }
  },
  created() {  
    this.loadSubscription();  
  },
  methods: {   
    loadSubscription(){
      this.$axios.get('/brands-manager/subscription', {
        params: {
          
        }
      })
      .then((response) => { 
        if(response.status === 200){                               
          this.data_subscription = response.data.data;
        }      
      })
      .catch(function (error) { 
        
      });
    }
  }
}*/
</script>

<style>



/**/
 
</style>

<style scoped>
 
 /* FORM SUBSCRIPTION */

.sform {
  display: flex;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #00000038;
}

.sform .info {
  background-color: #4bb21e;
  padding: 3rem;
  border-radius: 15px 0px 0px 15px;
}

.sform .data {
  padding: 3rem;
}

.sform .logo_pr {
  max-width: 200px;
}

.sform .info .row {
  border-top: 1px #ffffff6b solid;
  padding: 1rem 0;
}

.sform .info .row:first-child {
  border-top: 0px;
}

.sform .info .row label,
.sform .info .row .dt {
  color: white;
}

.sform .info .row h2 {
  color: white !important;
}

.sform .agree_check {

}
.sform .agree_check label{
  margin-left: 14px;
  display: inline-block;
  width: calc(100% - 30px);
  margin-top: -3px;
}

.sform .agree_check b{
  font-weight: bold;
  margin-top: 0.3rem;
  display: block;
}

.sform .agree_check a{
  color: blue;
  text-decoration: underline;
}


@media only screen and (max-width: 768px) {
  .sform {
    flex-direction: column;
  }
  .sform .info{

    border-radius: 15px 15px 0px 0px;
  }
}

/* **** */



/* table transactions */
@media only screen and (max-width: 768px) {
  .transaction_table thead{
    display: none!important;
  }
  .transaction_table tr{

  }
  .transaction_table td{

  }
  .transaction_table td:before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-align: left;
    /* white-space: nowrap; */
    width: 50%;
    border-bottom: 4px transparent solid;
  }
}

/* * * */

/* CANCEL SUBSCRIPTION */
.premium_active {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 4px 15px #00000021;
  border-radius: 10px;
  padding: 3rem;
  border-bottom: 1px #fca200 solid;
}

.premium_active .logo {
  width: 400px;
}

.premium_active .info {
  width: calc(100% - 500px);
  margin-left: auto;
}

.scancel {
  margin-bottom: 1rem;
}

.scancel a{
  color: #4183C4;
}
.scancel .cancel_link{
  color: #4183C4;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .premium_active{
    flex-direction: column;
  }
  .premium_active .logo{
    width: 100%;
    max-width: 400px;
    margin-bottom: 3rem;
  }
  .premium_active .info{
    margin-right: auto;
    width: 100%;
  }
}

</style>